import dayjs from 'dayjs';
import localizedFormatPlugin from 'dayjs/plugin/localizedFormat';
import utcPlugin from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import 'dayjs/locale/fr';

// Extend dayjs with utc and timezone plugins
dayjs.extend(utcPlugin);
dayjs.extend(localizedFormatPlugin);
dayjs.extend(timezone);
dayjs.extend(duration)
dayjs.extend(relativeTime)
dayjs.extend(customParseFormat)


export default dayjs;
